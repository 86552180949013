import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import Section2 from "../images/outros_section_2"
import Section3 from "../images/outros_section_3"
import Section4 from "../images/outros_section_4"

const Features = () => {
  return (
    <StyledSection id="features">
      <Subtitle>Características</Subtitle>
      <StyledContainer>
        <FeatureItem>
          <ImageWrapper>
            <Section2></Section2>
            <br />
          </ImageWrapper>
          <FeatureTextGroup>
            <h2>Canecas de porcelana</h2>
            <ul>
              <li>O processo de estampagem é a sublimação...</li>
              <li>Sem restrição quanto a estampa e quantidade.</li>
              <li>Estampa com ótimo brilho e durabilidade.</li>
            </ul>
          </FeatureTextGroup>
        </FeatureItem>
        <FeatureItem>
          <FeatureTextGroup>
            <h2>Adesivos laváveis</h2>
            <ul>
              <li>Disponíveis no opaco branco e no transparente.</li>
              <li>Ideal para rotular produtos, equipamentos e decoração.</li>
              <li>Por também ser sublimado resiste ao tempo e à lavagem.</li>
            </ul>
          </FeatureTextGroup>
          <ImageWrapper>
            <Section3></Section3>
            <br />
          </ImageWrapper>
        </FeatureItem>
        <FeatureItem>
          <ImageWrapper>
            <Section4></Section4>
            <br />
          </ImageWrapper>
          <FeatureTextGroup>
            <h2>Estampas coláveis</h2>
            <ul>
              <li>Próprias para aplicação em moletons, calças e bermudas.</li>
              <li>Excelente acabamento.</li>
              <li>Ultra-resistentes à lavagem.</li>
            </ul>
          </FeatureTextGroup>
        </FeatureItem>
      </StyledContainer>
    </StyledSection>
  )
}

export default Features

const StyledSection = styled(Section)`
  padding: 80px 0 0;
`

const StyledContainer = styled(Container)``

const FeatureTextGroup = styled.div`
  margin: 0 auto;
  padding-top: 16px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 0;
  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  > ul {
    @media (max-width: ${props => props.theme.screen.md}) {
      padding-left: 0;
    }
  }

  p {
    margin-bottom: 48px;
  }

  li {
    margin-bottom: 16px;
    ${props => props.theme.font_size.small}
  }
`

const FeatureItem = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 10vh;
  }

  margin-bottom: 20vh;
`

const ImageWrapper = styled.div`
  justify-self: center;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    grid-row: 2;
  }
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 36px;
  text-align: center;
`
