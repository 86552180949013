import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import WhatsAnchor from "../whatsanchor"

const Header = ({ header1, header2, subheader }) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "geral" }
        name: { eq: "itens-personalizados-estampa" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              {header1}
              <br />
              {header2}
            </h1>
            <p>{subheader}</p>
            <HeaderBlock>
              <WhatsAnchor>Encomendar</WhatsAnchor>
            </HeaderBlock>
            <ButtonSubtitle>(os pedidos são por WhatsApp)</ButtonSubtitle>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage
              fluid={data.file.childImageSharp.fluid}
              alt="Opções de itens para personalização"
            />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background.light};
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 120px 0 80px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  padding-top: 16px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  p {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 16px;
  padding-bottom: 8px;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const ButtonSubtitle = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }

  ${props => props.theme.font_size.xxxsmall}
`

const ImageWrapper = styled.div`
  justify-self: center;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 400px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 350px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 200px;
    display: none;
  }
`
