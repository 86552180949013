import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import Header from "../components/sections/outrosHeader"
import CallToAction from "../components/sections/calltoaction"
import Features from "../components/sections/outrosFeatures"
import Footer from "../components/sections/footer"

const CamisetasPage = () => {
  return (
    <Layout>
    <WhatsButton />
      <SEO
        title="Outros Produtos [Ouro Estamparia Digital]"
        description="Na Ouro Estamparia Digital também é possível personalizar canecas, moletons e adesivos laváveis."
        pathname="/contato"
      />
      <Navigation />
      <Header
        header1={`Itens personalizados com a sua estampa.`}
        header2="Sem restrições."
        subheader="Canecas de porcelana, adesivos laváveis e estampas para moleton e manga."
      />
      <Features></Features>
      <CallToAction
        msg1="Entre em contato ou faça um orçamento"
        sub="pedido das camisetas"
        link="orcamento"
      >
        Encomendar
      </CallToAction>
      <Footer />
    </Layout>
  )
}

export default CamisetasPage
